import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-10 gx-xl-10" }
const _hoisted_2 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Upload = _resolveComponent("Upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("attachments")), 1),
    _createVNode(_component_Upload, {
      fileList: _ctx.targetData.dialogImageUrl,
      "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetData.dialogImageUrl) = $event)),
      onHandleRemoveFile: _ctx.removeFileWithSupa,
      onHandleAddFile: _ctx.AddFileWithSupa
    }, null, 8, ["fileList", "onHandleRemoveFile", "onHandleAddFile"])
  ]))
}