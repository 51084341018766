
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import { defineComponent, onMounted, ref } from "vue";
import Upload from "@/components/shared/Upload.vue";
interface NewAddressData {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  dialogImageUrl: Array<any>;
}
export default defineComponent({
  name: "Files",
  components: { Upload },
  data() {
    return {
      activeName: "first",
    };
  },
  setup() {
    const targetData = ref<NewAddressData>({
      dialogImageUrl: [
        {
          name: "pdf-test.pdf",
          percentage: 0,
          status: "ready",
          size: 20597,
          raw: "[object File]",
          uid: 1638974895671,
          url: "https://image.shutterstock.com/image-photo/html-css-code-developing-screenshot-600w-479011165.jpg",
        },
        {
          name: "Capture du 2021-01-06 09-31-31.png",
          percentage: 0,
          status: "ready",
          size: 713,
          raw: "[object File]",
          uid: 1638974903076,
          url: "https://image.shutterstock.com/image-photo/software-programing-code-display-close-600w-1624885519.jpg",
        },
      ],
    });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Platforms", ["Pages", "Profile", "Files"]);
    });
    const removeFileWithSupa = (file, fileList) => {
      console.log("fileRemoved", file);
      console.log("fileList", fileList);
    };
    const AddFileWithSupa = (file, fileList) => {
      console.log("fileAdded", file);
      console.log("fileList", fileList);
    };
    return {
      targetData,
      removeFileWithSupa,
      AddFileWithSupa,
    };
  },
});
